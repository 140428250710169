require.context('../themes/site2/img', true);
// require.context('../themes/site2/fonts', true);

require("@rails/ujs").start()
require("@rails/activestorage").start()


require('../themes/site2/reset.css');
require('../themes/site2/buttons.css');
require('../themes/site2/header.css');
require('../themes/site2/footer.css');
require('../themes/site2/faq.css');
require('../themes/site2/reviews.css');
require('../themes/site2/registration.css');
require('../themes/site2/immersive.css');
require('../themes/site2/course.css');
require('../themes/site2/teachers.css');
require('../themes/site2/school_cases.css');
require('../themes/site2/bio_container.css');
require('../themes/site2/stats_container.css');
require('../themes/site2/credo_block.css');
require('../themes/site2/communication.css');
require('../themes/site2/metodics_text.css');
require('../themes/site2/metodics_gallery.css');
require('../themes/site2/blog-list.css');
require('../themes/site2/blog_detail.css');
require('../themes/site2/course-detail.css');
require('../themes/site2/conducted.css');


require('../themes/site2/main.css');


require("jquery");
import $ from 'jquery';

require('../themes/site2/js/script');
// require('../themes/site2/js/acordeon');


// Fancybox.bind('[data-fancybox]', {
//     // Custom options
// });


const swiper = new Swiper('.swiper', {
    direction: 'horizontal',
    slidesPerView: 'auto',
    spaceBetween: '5',
    loop: false,
    scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true,
    },
});
function getOffsets(element) {
    const rect = element.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const offsets = {
        top: rect.top,
        right: windowWidth - rect.right,
        bottom: windowHeight - rect.bottom,
        left: rect.left
    };
    return offsets;
}
function setElementWidth(element, offsets) {
    const newWidth = `calc(100% + ${offsets.left}px)`;
    element.style.width = newWidth;
}

// только на странице курса


// window.onload = function() {
//     const sliderElement = document.getElementById('slider');
//     const element = document.getElementById('conducted');
//     const offsets = getOffsets(element);
//     setElementWidth(sliderElement, offsets);
// };
